import { breakpointKeys, currentBreakpointIndex } from '../utils/windowResize';
import { onWindowResize } from './windowResize';
import { onScroll } from './scroll';
//import "objectFitPolyfill";

let lastUsedScreenWidth;
let lazyArray = [];

let options = {
    className: 'lazy',
    loadedClass: 'lazy--loaded',
    loadClass: 'lazy--loading',
    decodeImg: true, // This option requires promises support (incl. via polyfill.io)
    oldIe11Fit: false, // This option requires objectFit support
    offset: 0
};

export function setupLazyLoading(customOptions = {}) {
    lastUsedScreenWidth = -1;
    options = { ...options, ...customOptions };
    lazyArray = document.body.getElementsByClassName(options.className);

    // onWindowsResize set before first lazyLoad, to insure currentBreakPoint is set 
    onWindowResize(lazyLoad);

    onScroll(lazyLoad, true);
}

function lazyLoad() {
    // If our current screen mode does not match the one we used the last time we made an image lookup,
    // perform a new one now. Otherwise, what would be the point?
    
    if (lastUsedScreenWidth < currentBreakpointIndex) {
        
        for (let i = 0; i < lazyArray.length; i++) {
            const lazyElem = lazyArray[i];

            if (isInViewport(lazyElem)) {
                lazyElem.classList.add(options.loadClass);
                if (lazyElem.classList.contains('lazy--bg')) {
                    loadBgImage(lazyElem);
                } else {
                    loadLazyImage(lazyElem);
                }
            }
        }

        cleanLazy();
    }
}

function cleanLazy() {
    lazyArray = Array.prototype.filter.call(lazyArray, l => !l.classList.contains(options.loadClass));
}

function isInViewport(el) {
    const rect = el.getBoundingClientRect();

    return (
        rect.bottom >= 0 &&
        rect.right >= 0 &&
        rect.top - options.offset <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left - options.offset <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

/**
 * This function gets the image wrapper data attributes src and alt text
 * and creates an new image tag to download the image.
 * It then uses the src as a background-image.
 *
 * @param {HTMLElement} bgContainer - Image wrapper element
 */
export function loadBgImage(bgContainer) {
    const src = getImageSrc(bgContainer);
    const handleLoadedBg = () => {
        bgContainer.style.backgroundImage = formattedSrc;
        bgContainer.classList.add(options.loadedClass);
        bgContainer.classList.remove(options.loadClass);
    };

    // If no usable source was returned, abort at once.
    if (!src) {
        return;
    }

    const formattedSrc = `url(${src})`;

    if (bgContainer.style.backgroundImage === formattedSrc) {
        return;
    }

    if (options.decodeImg) {
        // Start loading the new image.
        loadImage(src).then(handleLoadedBg);
    } else {
        handleLoadedBg();
    }
}

/**
 * This function gets the container data attributes src.
 * If the container is an image it sets the src of it.
 * If the container is not an image it creates a new image tag and inserts it into the given container.
 *
 * @param {HTMLElement} container - Image wrapper element
 */
export function loadLazyImage(container) {
    const src = getImageSrc(container);

    // If no usable source was returned, abort mission.
    if (!src) {
        return;
    }

    if (options.decodeImg) {
        // We don't want to start processing if the new URL matches the old one.
        const oldImage = container.querySelector('img');
        if (oldImage && container.classList.contains(options.loadedClass)) {
            if (oldImage.getAttribute('src') === src) {
                if (options.oldIe11Fit) {
                    window.objectFitPolyfill(oldImage);
                }
                return;
            } else {
                container.removeChild(oldImage);
            }
        }

        // Start loading the new image.
        loadImage(src).then(newImageTag => {
            // Set src and ALT text if defined.
            if (container.tagName === 'IMG') {
                container.src = src;
            } else {
                const altText = container.getAttribute('data-alt') || '';
                newImageTag.setAttribute('alt', altText);

                container.appendChild(newImageTag);
            }

            container.classList.add(options.loadedClass);
            container.classList.remove(options.loadClass);

            // oldIE object-fit polyfill placed here to take resize into account
            if (options.oldIe11Fit) {
                window.objectFitPolyfill(newImageTag);
            }
        });
    } else {
        container.src = src;
        container.classList.add(options.loadedClass);
        container.classList.remove(options.loadClass);
    }
}

/**
 * Try to decode the image, after it's loaded, and resolve the Promise.
 *
 * @param {Element} newImage
 * @returns {Promise<Image>}
 */
function decodeImage(newImage) {
    return 'decode' in newImage ? newImage.decode().then(() => newImage) : Promise.resolve(newImage);
}

/**
 * Load an image, and return a Promise that resolves once the image is loaded.
 *
 * @param {string} path
 * @returns {Promise<Image>} Promise that will resolve with the loaded image once it's ready.
 */
export function loadImage(path) {
    const newImage = new Image();

    return new Promise(resolve => {
        newImage.addEventListener('load', () => decodeImage(newImage).then(image => resolve(image)), false);
        newImage.src = path;
    });
}

/**
 * This function gets the data-src from the image wrapper, based on width of the browser window.
 *
 * @param {HTMLElement} container - Image wrapper element
 * @returns {string}
 */
function getImageSrc(container) {
    let src = '';
    let largestBreakpointFound = 0;

    if (container.getAttribute('data-src')) {
        src = container.getAttribute('data-src');
        container.removeAttribute('data-src');
    } else {
        breakpointKeys.forEach((breakpointName, index) => {
            if (currentBreakpointIndex >= index) {
                if (index === 0 || index > largestBreakpointFound) {
                    const srcAttribute = `data-src-${breakpointName}`;
                    src = container.getAttribute(srcAttribute) || src;

                    container.removeAttribute(srcAttribute);

                    // Make sure we won't set the size to a smaller breakpoint later, in case they're not properly ordered.
                    largestBreakpointFound = index;
                }
            }
        });
    }

    return src;
}
