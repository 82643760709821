import { onReady } from './utils/onReady';
import { setupNav } from './components/nav';
import { setupLazyLoading } from './utils/lazyImage';
import { setupAccordions } from './components/accordion';
import { setupVideos } from './components/video';
import { initVhUnitOverwrite, showHideElem } from './utils/helpers';
import { version } from '../../package.json';
import { setupFilters } from './components/filter';
import { setupOverlay } from './components/overlay';
import { setupTabs } from './components/tabs';
import { setupSliders } from './components/slider';
import { watchFormFields } from './components/form';
import { setupUmbracoMemberLogin } from './components/umbraco-member';
import 'wa-mediabox'; // Docs: https://github.com/jirihybek/wa-mediabox
import { activateProfile } from './components/profile';


// Below are example of how to use import libraries installed via npm.
// import sal from 'sal.js';

function init() {
    document.body.classList.remove('standby');

    lazySizes.cfg.loadMode = 1;
    lazySizes.cfg.preloadAfterLoad = false;
    lazySizes.autoSizer.checkElems();

    setupNav('.nav', false);

    onReady(() => {
        initVhUnitOverwrite();

        setupLazyLoading();

        setupAccordions();

        setupFilters();

        setupOverlay();

        setupVideos();

        setupTabs();

        setupSliders();

        watchFormFields();

        setupUmbracoMemberLogin();

        activateProfile();

        showHideElem();
    });

    console.info(`fong kører - nedkogt til version ${version}`);
}

init();
