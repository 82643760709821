export function activateProfile() {
    const container = document.body.querySelector('.top-bar__profile');

    if (container) {
        const btn = container.querySelector('button');

        btn.addEventListener('click', e => {
            e.preventDefault();
            container.classList.toggle('open');
        });
    }
}
